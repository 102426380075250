import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import EcoSystemIntroCard from "../../../../V2/Cards/EcoSystem/Intro"

import { getUrlFromStoryblokLink } from "@utils/storyblok"

const StoryblokEcoSystemIntroCard = ({
  blok,
}: Storyblok.BlokProps<Storyblok.EcoSystemIntroCard>) => {
  return (
    <EcoSystemIntroCard
      backgroundColor={blok.backgroundColor}
      titleText={blok.titleText}
      descriptionText={blok.descriptionText}
      iconUrl={blok.icon?.filename}
      iconAlt={blok.icon?.alt}
      size={blok.size}
      buttonText={blok.size === "large" && blok?.ctaText ? blok.ctaText : ""}
      buttonLink={
        blok.size === "large" && blok.ctaLink
          ? getUrlFromStoryblokLink(blok.ctaLink)
          : ""
      }
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokEcoSystemIntroCard
